<template>
  <div class="personal-home">
    <div class="personal-home-bottom">
      <!-- <div class="personal-home-head">
        <div class="personal-home-title">
          我的课程
        </div>
      </div> -->
      <!-- 课程列表 -->
      <el-tabs v-model="activeName">
        <el-tab-pane label="未完成" name="未完成">
          <div class="course-content" v-loading="allCourseListLoading">
            <div
              class="course-content-div"
              v-for="(item, i) in finishList"
              :key="i"
            >
              <div class="content-img" @click="toSubject(item)">
                  <img
                          v-if="item.courseStatus!=0 || !item.smallCover"
                          src="@/assets/img/course.jpg"
                          alt="#"
                  />
                <img
                  v-else
                  :src="$store.getters.vodDomainName + item.smallCover"
                  alt=""
                />

                <!-- <div class="content-img-title-1">{{ item.year }}</div> -->
                <!-- <div class="content-img-title">{{ item.courseName }}</div> -->
              </div>
              <div class="content-message">
                <div class="content-messages">
                  <div class="content-title">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.courseName"
                      placement="top"
                    >
                      <span @click="toSubject(item)">
                        {{ item.courseName }}
                      </span>
                    </el-tooltip>
                  </div>
                  <div
                    v-if="item.courseStatus == 0"
                    style="width: 30%; float: left; text-align: right"
                  >
                    <img
                      v-if="item.progress < 100"
                      style="
                        width: 50px;
                        height: 50px;
                        right: 0;
                        top: 0.3125rem;
                      "
                      src="@/assets/img/pre/img-bad.png"
                    />
                    <img
                      v-if="item.progress == 100"
                      style="
                        width: 50px;
                        height: 50px;
                        right: 0;
                        top: 0.3125rem;
                      "
                      src="@/assets/img/pre/img-good.png"
                    />
                    <!-- img-lose.png -->
                  </div>
                  <div v-if="item.courseStatus!=0" style="width: 30%;float: left; text-align: right;">
                    <img style="width: 50px;height: 50px;right: 0;top: 0.3125rem"
                      src="@/assets/img/pre/img-lose.png">
                  </div>
                </div>
                <div class="content-img-title">
                  <el-progress
                    :percentage="item.progress"
                    :stroke-width="8"
                    color="#60C6FF"
                  >
                  </el-progress>
                </div>
                <div class="content-progress">
                  <span
                    ><i
                      class="el-icon-time"
                      style="font-weight: bold; font-size: 16px"
                    ></i>
                    {{ item.creditHours }}学时</span
                  >
                    <div v-if="item.courseStatus == 0" style="display: inline-block;">
                        <button

                                class="content-button"
                                @click="toSubject(item)"
                        >
                            进入课程
                        </button>
                        <button

                                class="content-button"
                                style="background-color: #e6a23c;"
                                @click="deleteItem(item)"
                        >
                            删除
                        </button>
                    </div>

                  <button
                    v-if="item.courseStatus != 0"
                    class="content-button"
                    style="background-color: #33333350"
                    @click="deleteCourse(item)"
                  >
                    删除
                  </button>
                </div>
              </div>

              <div class="content-line-title">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="item.name"
                  placement="top"
                >
                  <span>{{ item.courseName }}</span>
                </el-tooltip>
              </div>
              <div class="content-detail">
                <div style="display: flex; align-items: center">
                  <div>{{ item.type }}</div>
                  <div
                    style="
                      width: 0.0625rem;
                      height: 0.5rem;
                      background: #edeff2;
                      margin: 0 1rem;
                    "
                  ></div>
                  <div>{{ item.creditHours }}学时</div>
                </div>
                <el-button
                  class="delete"
                  type="text"
                  icon="cus-icon-delete-line"
                  @click.native.stop="deleteItem(item)"
                >
                  删除</el-button
                >
              </div>
            </div>
            <div style="min-height: 200px" v-if="!finishList.length > 0">
              暂无课程，到选课中心添加
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已完成" name="已完成">
          <div class="course-content" v-loading="allCourseListLoading">
            <div
              class="course-content-div"
              v-for="(item, i) in unFinishList"
              :key="i"
            >
              <div class="content-img" @click="toSubject(item)">
                <img
                  v-if="item.smallCover"
                  :src="$store.getters.vodDomainName + item.smallCover"
                  alt=""
                />
                <img
                  v-if="!item.smallCover"
                  src="@/assets/img/course.jpg"
                  alt="#"
                />
                <!-- <div class="content-img-title-1">{{ item.year }}</div> -->
                <!-- <div class="content-img-title">{{ item.courseName }}</div> -->
              </div>
              <div class="content-message">
                <div class="content-messages">
                  <div class="content-title">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.courseName"
                      placement="top"
                    >
                      <span @click="toSubject(item)">
                        {{ item.courseName }}
                      </span>
                    </el-tooltip>
                  </div>
                  <div style="width: 30%; float: left; text-align: right">
                    <img
                      v-if="item.progress < 100"
                      style="
                        width: 50px;
                        height: 50px;
                        right: 0;
                        top: 0.3125rem;
                      "
                      src="@/assets/img/pre/img-bad.png"
                    />
                    <img
                      v-if="item.progress == 100"
                      style="
                        width: 50px;
                        height: 50px;
                        right: 0;
                        top: 0.3125rem;
                      "
                      src="@/assets/img/pre/img-good.png"
                    />
                  </div>
                </div>
                <div class="content-img-title">
                  <el-progress
                    :percentage="item.progress"
                    :stroke-width="8"
                    color="#60C6FF"
                  >
                  </el-progress>
                </div>
                <div class="content-progress">
                  <span
                    ><i
                      class="el-icon-time"
                      style="font-weight: bold; font-size: 16px"
                    ></i>
                    {{ item.creditHours }}学时</span
                  >

                  <button class="content-button" @click="toSubject(item)">
                    进入课程
                  </button>
                </div>
              </div>

              <div class="content-line-title">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="item.name"
                  placement="top"
                >
                  <span>{{ item.courseName }}</span>
                </el-tooltip>
              </div>
              <div class="content-detail">
                <div style="display: flex; align-items: center">
                  <div>{{ item.type }}</div>
                  <div
                    style="
                      width: 0.0625rem;
                      height: 0.5rem;
                      background: #edeff2;
                      margin: 0 1rem;
                    "
                  ></div>
                  <div>{{ item.creditHours }}学时</div>
                </div>
                <el-button
                  class="delete"
                  type="text"
                  icon="cus-icon-delete-line"
                  @click.native.stop="deleteItem(item)"
                >
                  删除</el-button
                >
              </div>
            </div>
            <div style="min-height: 200px" v-if="!unFinishList.length > 0">
              暂无课程，到选课中心添加
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="已申请" name="已申请">
          <div class="course-content" v-loading="allCourseListLoading">
            <div
              class="course-content-div"
              v-for="(item, i) in requestedList"
              :key="i"
            >
              <div class="content-img" @click="toSubject(item)">
                <img
                  v-if="item.smallCover"
                  :src="$store.getters.vodDomainName + item.smallCover"
                  alt=""
                />
                <img
                  v-if="!item.smallCover"
                  src="@/assets/img/course.jpg"
                  alt="#"
                />
                <!-- <div class="content-img-title-1">{{ item.year }}</div> -->
                <!-- <div class="content-img-title">{{ item.courseName }}</div> -->
              </div>
              <div class="content-message">
                <div class="content-messages">
                  <div class="content-title">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="item.courseName"
                      placement="top"
                    >
                      <span>
                        {{ item.courseName }}
                      </span>
                    </el-tooltip>
                  </div>
                  <div style="width: 30%; float: left; text-align: right">
                    <img
                      v-if="item.progress < 100"
                      style="
                        width: 50px;
                        height: 50px;
                        right: 0;
                        top: 0.3125rem;
                      "
                      src="@/assets/img/pre/img-bad.png"
                    />
                    <img
                      v-if="item.progress == 100"
                      style="
                        width: 50px;
                        height: 50px;
                        right: 0;
                        top: 0.3125rem;
                      "
                      src="@/assets/img/pre/img-good.png"
                    />
                  </div>
                </div>
                <div class="content-img-title">
                  <el-progress
                    :percentage="item.progress"
                    :stroke-width="8"
                    color="#60C6FF"
                  >
                  </el-progress>
                </div>
                <div class="content-progress">
                  <span
                    ><i
                      class="el-icon-time"
                      style="font-weight: bold; font-size: 16px"
                    ></i>
                    {{ item.creditHours }}学时</span
                  >

                  <button
                    class="content-button"
                    style="background-color: #409eff70"
                    disabled
                    @click="toSubject(item)"
                  >
                    已申请
                  </button>
                </div>
              </div>

              <div class="content-line-title">
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="item.name"
                  placement="top"
                >
                  <span>{{ item.courseName }}</span>
                </el-tooltip>
              </div>
              <div class="content-detail">
                <div style="display: flex; align-items: center">
                  <div>{{ item.type }}</div>
                  <div
                    style="
                      width: 0.0625rem;
                      height: 0.5rem;
                      background: #edeff2;
                      margin: 0 1rem;
                    "
                  ></div>
                  <div>{{ item.creditHours }}学时</div>
                </div>
                <el-button
                  class="delete"
                  type="text"
                  icon="cus-icon-delete-line"
                  @click.native.stop="deleteItem(item)"
                >
                  删除</el-button
                >
              </div>
            </div>
            <div style="min-height: 200px" v-if="!requestedList.length > 0">
              暂无课程，到选课中心添加
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <my-dialog
        title="提示"
        :visible.sync="deleteFlag"
        @before-close="refreshLX"
        width="363px"
      >
        <div class="message">
          删除之后，相关学时也将对应减少，是否确认删除？
        </div>

        <el-row>
          <el-col class="flex_end_j" style="text-align: center">
            <div
              class="flex_cancel"
              style="text-align: center"
              @click="deleteFlag = false"
            >
              取消
            </div>
            <div class="flex_confirm" @click="removeItem">确定</div>
          </el-col>
        </el-row>
      </my-dialog>
    </div>
  </div>
</template>

<script>
import { httpGet, httpPost, httpNPPost, httpNPGet } from "@/utils/httpRequest";
export default {
  name: "PersonalHome",
  data() {
    return {
      // 公告加载
      noticeMessLoading: true,
      // 我的课程加载
      allCourseListLoading: true,
      activeName: "未完成",
      noticeMess: {
        title: "【公告标题公告标题公告标题】",
        content:
          "公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告" +
          "内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告内容公告",
        time: "10月15日 13:50",
      },
      finishList: [],
      unFinishList: [],
      requestedList: [],
      deleteFlag: false,
    };
  },
  methods: {
    // 删除
    deleteCourse(item) {
      // console.log(item);
      httpNPGet(
        "/app/app-user-course-study/delete?studyId=" + item.studyId,
        (res) => {
          // console.log(res);
          this.$message.success("删除成功");
          this.deleteFlag = false;
          // this.getSubjects();
            location.reload();
        },
        (err) => {
          if (err.code == 500) {
            if (err.msg == "token异常，请重新登录") {
              localStorage.clear();
              if (!localStorage.getItem("userId")) {
                this.$message.error("登录过期，请重新登录!");
                this.$router.push("/login");
              }
            }
          }
          this.deleteFlag = false;
          // console.log(err);
          throw err;
        }
      );
    },
    // 路由跳转
    toBulletinMore() {
      this.$router.push({ name: "BulletinMore" });
    },
    toBulletinDetails() {
      this.$router.push({
        name: "BulletinDetails",
        params: { noticeId: this.noticeMess.id },
      });
    },
    clickDetails(data) {
      // console.log(data)
      this.$router.push({ name: "CourseDetails", query: data });
    },
    // 删除
    removeItem() {
      // console.log(this.deleteMsg)
        this.deleteCourse(this.deleteMsg);
    },
    refreshLX() {
      this.deleteFlag = false;
    },
    deleteItem(item) {
      // console.log(item);
      this.deleteMsg = item;
      this.deleteFlag = true;
    },
    goToSubject() {
      this.$router.push("/MineCourse");
    },
    // 获取公告
    getNotice() {
      httpGet(
        "/news-info/list",
        {
          typeId: 1,
        },
        (res) => {
          this.noticeMess = res.data.records[0];
          // console.log(this.noticeMess);
          this.noticeMessLoading = false;
        },
        (err) => {
          // console.log(err);
          throw err;
        }
      );
    },
    // 进入课程视屏播放页面
    toSubject(item) {
      // console.log(item.courseId);
        if(item.courseStatus == 0) {
            this.$router.push({
                path: "/videocatalog",
                query: {
                    courseName: item.courseName,
                    courseId: item.courseId,
                },
            });
        } else {
            // this.$message.error("视频已失效，只能删除操作");
        }
    },
    // 获取我的课程列表
    getSubjects() {
      httpGet(
        "/app/app-user-course-study/list",
        {
          userId: localStorage.getItem("userId"),
        },
        (res) => {
          // finishList: [],
          // unFinishList: [],
          // requestedList: [],
          // console.log(res.data);
          for (const index in res.data) {
            if(res.data[index].status==0){
              this.finishList.push(res.data[index])
            }else if(res.data[index].status==1){
              this.unFinishList.push(res.data[index])
            }else if(res.data[index].status==2){
              this.requestedList.push(res.data[index])
            }
          }
          this.allCourseList = res.data;
          this.allCourseListLoading = false;
        },
        (err) => {
          if (err.code == 500) {
            if (err.msg == "token异常，请重新登录") {
              localStorage.clear();
              if (!localStorage.getItem("userId")) {
                // this.$message.error('登录过期，请重新登录!')
                this.$router.push("/login");
              }
            }
          }
          // console.log(err);
          throw err;
        }
      );
    },
  },
  mounted() {
    this.getSubjects();
    // 获取咨询
    // this.getNotice();
  },
};
</script>

<style lang="scss" scoped>
.personal-home {
  padding: 0 20px;
  background: #ffffff;
  border-radius: 10px;
  overflow: auto;
  height: 792px;

  .message {
    font-size: 1.125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5rem;
    text-align: left;
    padding: 0.75rem 6.61157% 3rem 8.8154%;
  }

  .message::before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url("~@/assets/img/pre/icon-tip-1.png");
    background-size: 100% 100%;
    margin-right: 4px;
  }

  .flex_end_j {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    padding-right: 4.40771%;
    height: 3.25rem;
    border-top: 0.0625rem solid #edeff2;

    .flex_cancel {
      width: 15.97796%;
      height: 2rem;
      background: #ffffff;
      border-radius: 10px;
      opacity: 1;
      border: 0.0625rem solid #e6e8eb;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3e454d;
      line-height: 2rem;
      margin-right: 2.75482%;
      cursor: pointer;
    }

    .flex_confirm {
      display: flex;
      justify-content: center;
      width: 15.97796%;
      height: 2rem;
      background: #1371e7;
      box-shadow: 0px 0.25rem 0.375rem 0px rgba(19, 113, 231, 0.08);
      border-radius: 10px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 2rem;
      cursor: pointer;
    }
  }

  .personal-home-top {
    padding: 0 2rem;
    height: 13.625rem;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 1rem;
    overflow: hidden;

    .personal-home-top-content {
      cursor: pointer;
      text-align: left;

      .title {
        height: 1.375rem;
        font-size: 1rem;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 1.1875rem;
      }

      .content {
        //height: 62px;
        width: 93.2275%;
        font-size: 0.875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #3e454d;
        line-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-top: 0.5rem;
        overflow: hidden;
      }

      .time {
        height: 1.25rem;
        font-size: 0.875rem;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #667280;
        line-height: 1rem;
        text-align: right;
        margin-top: 0.625rem;
      }
    }

    .announcement {
      height: 1.25rem;
      font-size: 0.875rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
      line-height: 1rem;
    }
  }

  .personal-home-bottom {
    padding: 0;
    // height: 34.875rem;
    // background: #FFFFFF;
    // box-shadow: 0 0.125rem 0.25rem 0 rgba(3, 27, 78, 0.06);
    border-radius: 10px;

    .course-content {
      display: grid;
      grid-template-columns: repeat(auto-fill, 49%);
      justify-content: space-between;
      // height: 28.75rem;
      // overflow-y: scroll;
      margin-top: 1.25rem;
      overflow: auto;
      overflow-x: hidden;

      .delete {
        color: #d65b6b;
        display: none;
        transition-delay: 0.1s;
      }

      .course-content-div:hover .delete {
        display: flex;
        align-items: center;
        transition-delay: 0.1s;
        z-index: 999;
      }

      .course-content-div {
        width: 100%;
        height: 170px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 10px;
        overflow: hidden;
        margin: 10px 0;
        box-sizing: border-box;
        padding: 20px;
        // margin-bottom: 1.75rem;
        border: 0.0625rem solid #edeff2;

        .content-img {
          position: relative;
          float: left;
          width: 50%;
          box-sizing: border-box;
          height: 130px;
          cursor: pointer;

          img {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
          }

          /* 当前状态颜色 */
          ::v-deep .el-progress-bar__outer {
            background: rgba(1, 1, 1, 0.23);
          }

          ::v-deep .el-progress-bar {
            height: 1rem;
          }

          ::v-deep .el-progress__text {
            display: inline-block;
            vertical-align: middle;
            height: 1.5rem;
            font-size: 0.8125rem;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            margin-left: 0.625rem;
            line-height: 1;
          }
        }

        .content-message {
          width: 50%;
          box-sizing: border-box;
          padding-left: 12px;
          float: left;

          .content-messages {
            height: 55px;
            margin-bottom: 10px;

            .content-title {
              width: 70%;
              height: 100%;
              // line-height: 55px;
              float: left;
              text-align: left;
              cursor: pointer;

              span {
                display: inline-block;
                width: 100%;
                text-align: left;
                font-size: 16px;
                font-weight: bold;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical; //盒子中内容竖直排列
              }
            }
          }

          .content-img-title {
            text-align: left;
            margin-top: 5px;
          }
        }

        .content-progress {
          margin-top: 20px;
          width: 100%;
          float: left;
          text-align: right;

          span {
            line-height: 30px;
            color: #1e9eff;
            font-weight: bold;
            font-size: 14px;
            float: left;
          }

          .content-button {
            border-radius: 15px;
            box-sizing: border-box;
            background-color: #409eff;
            color: #ffffff;
            border: 0px;
            padding: 6px 13px;
            outline: none;
            cursor: pointer;
          }
        }

        .content-line-title {
          margin: 0.625rem 6.42857% 0.8125rem 6.42857%;
          height: 3.25rem;
          font-size: 1rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 1.625rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
        }

        .content-detail {
          margin: 0.625rem 6.4285% 0.8125rem 6.4285%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 1.25rem;
          font-size: 0.75rem;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 1.25rem;
        }
      }
    }
  }

  .personal-home-head {
    display: flex;
    height: 4rem;
    line-height: 4rem;
    justify-content: space-between;
    border-bottom: 0.0625rem solid #edeff2;

    .more {
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      width: 9.08059%;
      cursor: pointer;
    }

    .more:hover {
      color: #00a1d6;
    }

    .personal-home-title {
      width: 100%;
      text-align: left;
      font-size: 1.25rem;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
    }
  }

  // tabs
  ::v-deep .el-tabs {
    background-color: #ffffff;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1371e7;
    height: 0.25rem;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #000000;
  }

  ::v-deep .el-tabs__item {
    height: 4rem;
    line-height: 4rem;
    font-size: 0.9375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 2.5rem;
    color: #667280;
  }
}
</style>
